

























import '@/common/map'
import {AMapManager} from 'vue-amap'

let amapManager = new AMapManager();

export default {
    name: "el-map",
    props: {
        width: {
            type: String,
            default() {
                return '500px'
            }
        },
        height: {
            type: String,
            default() {
                return '300px'
            }
        },
        searchBox: {
            type: Boolean,
            default() {
                return false;
            }
        },
        point: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        let self: any = this;
        return {
            amapManager,
            loading: true,
            zoom: 14,
            lng: 104.06683,
            lat: 30.64937,
            center: [104.06683, 30.64937],
            markerPosition: [],
            marker: {
                events: {
                    dragend: (e: any) => {
                        self.changeMarkerPosition(e)
                    }
                }
            },
            markers: [],
            searchOption: {},
            events: {
                click(e: any) {
                    self.changeMarkerPosition(e)
                }
            },
            plugin: [{
                pName: 'Geolocation',
                events: {
                    init(o: any) {
                        // o 是高德地图定位插件实例
                        o.getCurrentPosition((status: any, result: any) => {
                            self.loading = false;
                            if (result && result.position) {
                                self.center = [result.position.lng, result.position.lat];
                                self.markerPosition = self.center;
                                self.$nextTick().then();
                            }
                        })
                    }
                }
            }]
        }
    },
    watch: {
        point(newValue: any) {
            let self: any = this;
            if (newValue && newValue.length > 0) {
                self.center = [newValue[0], newValue[1]]
            }
        }
    },
    methods: {
        changeMarkerPosition(e: any) {
            let self: any = this;
            let {lng, lat} = e.lnglat;
            self.lng = lng;
            self.lat = lat;
            self.markerPosition = [lng, lat];
            // 这里通过高德 SDK 完成。
            let geocoder = new AMap.Geocoder({
                radius: 1000,
                extensions: "all"
            });
            geocoder.getAddress([lng, lat], (status: string, result: any) => {
                if (status === 'complete' && result.info === 'OK') {
                    if (result && result.regeocode) {
                        let regeocode = result.regeocode;
                        let addressComponent = regeocode.addressComponent;
                        self.$nextTick().then(() => {
                            self.$emit('on-marker-changed', {
                                lat: lat,
                                lng: lng,
                                addressComponent: addressComponent
                            });
                        });
                    }
                }
            });
        },
        onSearchResult(pois: any) {
            let self: any = this;
            self.markers = [];
            let latSum = 0;
            let lngSum = 0;
            if (pois.length > 0) {
                pois.forEach((poi: any) => {
                    let {lng, lat} = poi;
                    lngSum += lng;
                    latSum += lat;
                    self.markers.push([poi.lng, poi.lat]);
                });
                self.center = [lngSum / pois.length, latSum / pois.length];
                self.zoom = 12;
            }
        }
    }
}
