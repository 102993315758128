import Vue from 'vue'
import VueAMap from 'vue-amap'
Vue.use(VueAMap);
/**
 * 高德地图配置
 */
VueAMap.initAMapApiLoader({
    key: 'cb4fea0185f7584cb04e3d1d39a9fdea',
    plugin: ['AMap.Geocoder', 'AMap.Geolocation', 'AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
    // 默认高德 sdk 版本为 1.4.4
    v: '1.4.4',
    uiVersion: '1.0.11'
});
